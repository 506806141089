import classNames from "classnames"
import React, { useEffect, useState } from "react"
import Marquee from "react-marquee-slider"

import "./business-parameter-grid.sass"

const BusinessParameterGrid = ({ children }) => {
  const [init, setInit] = useState(false)

  useEffect(() => {
    setInit(true)
  }, [])

  return (
    <div
      className={classNames("_fp-business-parameter-grid", {
        "_fp-business-parameter-grid--not-init": !init,
      })}
    >
      <Marquee>{children}</Marquee>
    </div>
  )
}

export default BusinessParameterGrid
