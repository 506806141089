/**
 * SEO component that queries for data with
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Helmet from "react-helmet"
import { stripHtml } from "string-strip-html"

import { Locale } from "../i18n/locale"
import { removeLocaleFromUri } from "../i18n/removeLocaleFromUri"
import { useTranslation } from "../i18n/translate"

function SEO({
  descriptionTranslatedString,
  pathName,
  showPageTitleSuffix,
  titleTranslatedString,
  primaryImage,
  children,
  indexPage,
  availableLanguages,
  ...props
}) {
  // Exceptions
  if (props.title) {
    throw new Error('"title" prop is deprecated')
  }
  if (props.description) {
    throw new Error('"description" prop is deprecated')
  }
  //

  const { i18n, t } = useTranslation()
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
            author
            logoUrl
            organisationLegalName
            organisationAlternateName
            organisationName
          }
        }
      }
    `,
  )

  const metaDescription =
    stripHtml(descriptionTranslatedString || "").result ||
    t("HOME-META_DESCRIPTION")

  const currentLocale = new Locale(i18n.language)

  return (
    <Helmet
      htmlAttributes={{ lang: i18n.language }}
      title={titleTranslatedString}
      titleTemplate={`%s${
        showPageTitleSuffix ? " | " + site.siteMetadata.title : ""
      }`}
    >
      {/*Noindex*/}
      {!indexPage && <meta name="robots" content="noindex" />}

      {/*Favicon*/}
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/favicon/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon/favicon-16x16.png"
      />
      <link rel="manifest" href="/favicon/site.webmanifest" />
      <link
        rel="mask-icon"
        href="/favicon/safari-pinned-tab.svg"
        color="#0d6b4e"
      />
      <meta name="msapplication-TileColor" content="#0d6b4e" />
      <meta name="msapplication-config" content="/favicon/browserconfig.xml" />
      <meta name="theme-color" content="#0d6b4e" />
      <meta
        name="apple-mobile-web-app-title"
        content={site.siteMetadata.title}
      />
      <meta name="application-name" content={site.siteMetadata.title} />
      {/*End Favicon*/}

      {/* Localized SEO */}
      {Locale.getAllSiteLocales(availableLanguages).map(locale => {
        return (
          <link
            key={`locale_${locale.key}`}
            rel="alternate"
            hrefLang={locale.key}
            href={`${site.siteMetadata.siteUrl}/${
              locale.key
            }${removeLocaleFromUri(pathName)}/`.replace(/\/\/$/g, "/")}
          />
        )
      })}

      {(!availableLanguages || availableLanguages.includes("en")) && (
        <link
          rel="alternate"
          href={(
            site.siteMetadata.siteUrl +
            "/en" +
            removeLocaleFromUri(pathName) +
            "/"
          ).replace(/\/\/$/g, "/")}
          hrefLang="x-default"
        />
      )}

      <link
        rel="canonical"
        href={(site.siteMetadata.siteUrl + pathName + "/").replace(
          /\/\/$/g,
          "/",
        )}
      />

      {/* End Localized SEO */}

      <meta name="description" content={metaDescription} />
      <meta
        property="og:title"
        content={titleTranslatedString + " | " + site.siteMetadata.title}
      />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content={(site.siteMetadata.siteUrl + pathName + "/").replace(
          /\/\/$/g,
          "/",
        )}
      />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={site.siteMetadata.author} />
      <meta
        name="twitter:title"
        content={titleTranslatedString + " | " + site.siteMetadata.title}
      />
      <meta name="twitter:description" content={metaDescription} />

      {/* Schema */}

      <script type="application/ld+json">
        {`{
                    "@context": "https://schema.org",
                    "@graph": [
                        {
                            "@type": "Corporation",
                            "@id": "${site.siteMetadata.siteUrl}/#organisation",
                            "url": "${site.siteMetadata.siteUrl}",
                            "name": "${site.siteMetadata.organisationName}",
                            "alternateName": "${
                              site.siteMetadata.organisationAlternateName
                            }",
                            "legalName": "${
                              site.siteMetadata.organisationLegalName
                            }",
                            "logo": "${site.siteMetadata.logoUrl}",
                            "contactPoint": {
                                "@type": "ContactPoint",
                                "telephone": "+4971319870",
                                "contactType": "Office",
                                "email": "info@munzing.com"
                            },
                            "address": {
                                "streetAddress": "Münzingstraße 2",
                                "postalCode": "74232",
                                "addressCountry": "DE",
                                "addressRegion": "Baden-Württemberg",
                                "addressLocality": "Abstatt"
                            },
                            "sameAs": [
                                "https://www.youtube.com/channel/UCTy9TPPPcZn3NmcBunFxhyQ",
                                "https://de.wikipedia.org/wiki/M%C3%BCnzing_Chemie",
                                "https://www.linkedin.com/company/m%C3%BCnzing-chemie-gmbh"
                            ]
                        },
                        {
                            "@type":"WebSite",
                            "@id":"${site.siteMetadata.siteUrl}/#website",
                            "url":"${site.siteMetadata.siteUrl}",
                            "name": "${site.siteMetadata.title}",
                            "description":"${t("HOME-META_DESCRIPTION")}",
                            "inLanguage":"${i18n.language}",
                            "publisher":{"@id":"${
                              site.siteMetadata.siteUrl
                            }/#organisation"},
                    "potentialAction": {
                                "@type": "SearchAction",
                                "target": "${
                                  site.siteMetadata.siteUrl
                                }/search/?q={search_term_string}",
                                "query-input": "required name=search_term_string"
                            }
                        }
                        
                    ]
                }`}
      </script>
      {pathName && (
        <script type="application/ld+json">
          {`{
                    "@context":"https://schema.org",
                    "@type":"WebPage",
                    "@id":"${(
                      site.siteMetadata.siteUrl +
                      pathName +
                      "/"
                    ).replace(/\/\/$/g, "/")}#webpage",
                    "url":"${(
                      site.siteMetadata.siteUrl +
                      pathName +
                      "/"
                    ).replace(/\/\/$/g, "/")}",
                    "name":"${titleTranslatedString +
                      (showPageTitleSuffix
                        ? " | " + site.siteMetadata.title
                        : "")}",
                    "isPartOf":{"@id":"${site.siteMetadata.siteUrl}/#website"},
                    "description":"${metaDescription}", 
                    ${
                      primaryImage
                        ? `"primaryImageOfPage": {"@id":"${site.siteMetadata
                            .siteUrl + primaryImage}", "contentUrl": "${site
                            .siteMetadata.siteUrl + primaryImage}"},`
                        : ""
                    }
                    "inLanguage":"${i18n.language}"
                }`}
        </script>
      )}

      {children}
    </Helmet>
  )
}

SEO.defaultProps = {
  showPageTitleSuffix: true,
  indexPage: true,
}

SEO.propTypes = {
  pathName: PropTypes.string,
  indexPage: PropTypes.bool,
  showPageTitleSuffix: PropTypes.bool,
  descriptionTranslatedString: PropTypes.string,
  titleTranslatedString: PropTypes.string.isRequired,
  children: PropTypes.node,
  primaryImage: PropTypes.string,
  availableLanguages: PropTypes.array,
}

export default SEO
