import classNames from "classnames"
import React from "react"

import useEvents from "../../hooks/useEvents"
import { useTranslation } from "../../i18n/translate"
import { sortEventsByDate } from "../../util/event-helpers"
import Tile from "../tile/tile"
import TileContentSpacer from "../tile/tile-content-spacer/tile-content-spacer"
import EventPreview from "./event-preview/event-preview"
import "./events-tile.sass"

const EventsTile = ({ lang }) => {
  const { t } = useTranslation()
  const events = useEvents(lang)

  return (
    <Tile
      titleTranslatedString={t("MUNZING_GROUP-NEWS_AND_EVENTS-NEWS_AND_EVENTS")}
      linkTextTranslatedString={t(
        "MUNZING_GROUP-NEWS_AND_EVENTS-MORE_NEWS_AND_EVENTS",
      )}
      linkPath={"/munzing-group/news-and-events/"}
    >
      <div
        className={classNames(
          "_fp-events-tile",
          `i${sortEventsByDate(events).slice(0, 3).length}`,
        )}
      >
        {sortEventsByDate(events)
          .slice(0, 3)
          .map((event, index, array) => (
            <span
              className={classNames("_fp-events-tile__item", {
                "_fp-events-tile__item--last":
                  array.length - 1 === index && index === 2,
              })}
              key={`event_${index}`}
            >
              <EventPreview event={event} />
              {index !== 2 && <TileContentSpacer size={"large"} />}
            </span>
          ))}
      </div>
    </Tile>
  )
}

export default EventsTile
