import { graphql } from "gatsby"
import React from "react"

import BusinessParameterGrid from "../components/business-parameter-grid/business-parameter-grid"
import BusinessParameter from "../components/business-parameter/business-parameter"
import EventsTile from "../components/events/events-tile"
import BrandPreview from "../components/generic-preview/brand-preview/brand-preview"
import MarketPreview from "../components/generic-preview/market-preview/market-preview"
import ImageSlider from "../components/image-slider/image-slider"
import OverviewSlider from "../components/overview-slider/overview-slider"
import SEO from "../components/seo"
import Tile from "../components/tile/tile"
import TileContentSpacer from "../components/tile/tile-content-spacer/tile-content-spacer"
import { VirtualTourPreview } from "../components/virtual-tour-preview/virtual-tour-preview"
import useBrands from "../hooks/useBrands"
import useMarkets from "../hooks/useMarkets"
import useNav from "../hooks/useNav"
import { useTranslation } from "../i18n/translate"
import EmployeesIcon from "../images/icons/employees.svg"
import GlobalIcon from "../images/icons/global.svg"
import ProductionFacilitiesIcon from "../images/icons/production-facilities.svg"
import RDIcon from "../images/icons/rd.svg"
import { dynamicImageWithGatsbyImage } from "../util/dynamic-image"

const IndexPage = ({ data, location, pageContext }) => {
  const { t } = useTranslation()
  const brands = useBrands()
  const markets = useMarkets()
  const munzingGroupNav = useNav({ uid: "munzing-group" })

  return (
    <>
      <SEO
        pathName={location.pathname}
        showPageTitleSuffix={false}
        titleTranslatedString={t("HOME-META_TITLE")}
      />

      <ImageSlider
        items={[
          { image: dynamicImageWithGatsbyImage(data.hero) },
          { image: dynamicImageWithGatsbyImage(data.hero2) },
          { image: dynamicImageWithGatsbyImage(data.hero3) },
          { image: dynamicImageWithGatsbyImage(data.hero4) },
        ]}
      />
      <VirtualTourPreview></VirtualTourPreview>

      <div className="_fp-global-container _fp-grid--margin-top">
        <div className="_fp-grid _fp-grid--gap">
          <div className="_fp-col-7 _fp-col-12t _fp-col-12st _fp-col-12p _fp-col-12sp">
            <Tile
              titleTranslatedString={t("HOME-ABOUT_MUNZING-TITLE")}
              linkPath={munzingGroupNav.url}
              linkTextTranslatedString={t(
                "HOME-ABOUT_MUNZING-MORE_ABOUT_MUNZING",
              )}
            >
              <p className={"_fp-text _fp-text--columns _fp-text--drop-caps"}>
                {t("HOME-ABOUT_MUNZING-TEXT")}
              </p>
              <TileContentSpacer />
              <BusinessParameterGrid>
                <BusinessParameter
                  iconSvgComponent={<EmployeesIcon />}
                  title={t(
                    "HOME-BUSINESS_PARAMETERS-EMPLOYEES_WORLDWIDE-TITLE",
                  )}
                  number={t(
                    "HOME-BUSINESS_PARAMETERS-EMPLOYEES_WORLDWIDE-VALUE",
                  )}
                />
                <BusinessParameter
                  iconSvgComponent={<GlobalIcon />}
                  title={t("HOME-BUSINESS_PARAMETERS-COUNTRY_PRESENCE-TITLE")}
                  number={t("HOME-BUSINESS_PARAMETERS-COUNTRY_PRESENCE-VALUE")}
                />
                <BusinessParameter
                  iconSvgComponent={<RDIcon />}
                  title={t("HOME-BUSINESS_PARAMETERS-R&D_FACILITIES-TITLE")}
                  number={t("HOME-BUSINESS_PARAMETERS-R&D_FACILITIES-VALUE")}
                />
                <BusinessParameter
                  iconSvgComponent={<ProductionFacilitiesIcon />}
                  title={t("HOME-BUSINESS_PARAMETERS-PRODUCTION_SITES-TITLE")}
                  number={t("HOME-BUSINESS_PARAMETERS-PRODUCTION_SITES-VALUE")}
                />

                <BusinessParameter
                  iconSvgComponent={<EmployeesIcon />}
                  title={t(
                    "HOME-BUSINESS_PARAMETERS-EMPLOYEES_WORLDWIDE-TITLE",
                  )}
                  number={t(
                    "HOME-BUSINESS_PARAMETERS-EMPLOYEES_WORLDWIDE-VALUE",
                  )}
                />
                <BusinessParameter
                  iconSvgComponent={<GlobalIcon />}
                  title={t("HOME-BUSINESS_PARAMETERS-COUNTRY_PRESENCE-TITLE")}
                  number={t("HOME-BUSINESS_PARAMETERS-COUNTRY_PRESENCE-VALUE")}
                />
                <BusinessParameter
                  iconSvgComponent={<RDIcon />}
                  title={t("HOME-BUSINESS_PARAMETERS-R&D_FACILITIES-TITLE")}
                  number={t("HOME-BUSINESS_PARAMETERS-R&D_FACILITIES-VALUE")}
                />
                <BusinessParameter
                  iconSvgComponent={<ProductionFacilitiesIcon />}
                  title={t("HOME-BUSINESS_PARAMETERS-PRODUCTION_SITES-TITLE")}
                  number={t("HOME-BUSINESS_PARAMETERS-PRODUCTION_SITES-VALUE")}
                />
              </BusinessParameterGrid>

              <TileContentSpacer />
            </Tile>
          </div>
          <div className="_fp-col-5 _fp-col-12t _fp-col-12st _fp-col-12p _fp-col-12sp">
            <EventsTile lang={pageContext.locale} />
          </div>
          <div className="_fp-col-12" data-nosnippet>
            <OverviewSlider
              title={t("MARKETS-MARKETS")}
              linkPath={"/markets/"}
              linkTextTranslatedString={t("MARKETS-VIEW_ALL_MARKETS")}
            >
              {markets
                .filter((market) => !market.hidden)
                .sort((a, b) => {
                  return t(a.name.key).localeCompare(t(b.name.key))
                })
                .map((market) => {
                  return (
                    <MarketPreview market={market} key={`key_${market.id}`} />
                  )
                })}
            </OverviewSlider>
          </div>
          <div className="_fp-col-12" data-nosnippet>
            <OverviewSlider
              title={t("BRANDS-BRANDS")}
              linkPath={"/products/"}
              linkTextTranslatedString={t("BRANDS-VIEW_ALL_BRANDS")}
            >
              {brands
                .filter((brand) => !brand.hidden)
                .sort((a, b) => {
                  return t(a.name.key).localeCompare(t(b.name.key))
                })
                .map((brand) => {
                  return <BrandPreview brand={brand} key={`key_${brand.id}`} />
                })}
            </OverviewSlider>
          </div>
        </div>
      </div>
    </>
  )
}

export default IndexPage

export const query = graphql`
  {
    hero: strapiDynamicImage(uid: { eq: "HOME_HERO" }) {
      ...DynamicImageWithGatsbyImage
    }
    hero2: strapiDynamicImage(uid: { eq: "HOME_HERO_2" }) {
      ...DynamicImageWithGatsbyImage
    }
    hero3: strapiDynamicImage(uid: { eq: "HOME_HERO_3" }) {
      ...DynamicImageWithGatsbyImage
    }
    hero4: strapiDynamicImage(uid: { eq: "HOME_HERO_4" }) {
      ...DynamicImageWithGatsbyImage
    }
  }
`
