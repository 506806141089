import { LinearSlideStyle, Slider } from "@fp/react-slider"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

import { useTranslation, withTranslation } from "../../i18n/translate"
import { dynamicImageWithGatsbyImage } from "../../util/dynamic-image"
import {
  getDynamicPdfFileUrl,
  getPdfFileItemForLanguage,
} from "../dynamic-pdf-file/dynamic-pdf-file"
import "./image-slider.sass"
import SliderIndicators from "./slider-indicators/slider-indicators"

class ImageSlider extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeIndex: 0,
      goToIndex: () => {},
    }
  }

  render() {
    const items = this.props.items || []
    const locale = this.props.i18n.language || "en"

    return (
      <div style={{ position: "relative" }}>
        <div className={"_fp-image-slider"}>
          <Slider
            onSlideChange={(index) => {
              this.setState({ activeIndex: index })
            }}
            onMount={({ goToIndex }) => {
              this.setState({ goToIndex })
            }}
            slideStyle={new LinearSlideStyle()}
            interval={10000}
          >
            {items.map((item, index) => {
              return (
                <div key={`slide_${index}`} className="_fp-image-slider__slide">
                  <GatsbyImage
                    imgStyle={{ objectFit: "cover", objectPosition: "center" }}
                    style={{ height: "100%" }}
                    image={item.image}
                    alt=""
                  />
                </div>
              )
            })}
          </Slider>
          <SliderIndicators
            items={items}
            activeIndex={this.state.activeIndex}
            goToIndex={this.state.goToIndex}
          />
        </div>
        <div className="_fp-image-slider__badges">
          {(this.props.pdfFileBadges || []).map(({ image, pdf }, index) => {
            const pdfFileItem = getPdfFileItemForLanguage(locale, pdf)
            return (
              <a
                target={"_blank"}
                href={getDynamicPdfFileUrl(pdfFileItem, pdf)}
                key={`slider-badge-${index}`}
                className={"_fp-image-slider-badge"}
              >
                <img
                  className={"_fp-image-slider-badge"}
                  src={image.images.fallback.src}
                  alt=""
                />
              </a>
            )
          })}

          <img
            className={"_fp-image-slider-badge"}
            src="/images/hero/ecovadis_medal_2023.png"
            alt=""
          />

          <img
            className={"_fp-image-slider-badge"}
            src="/images/hero/t100_2023-en.jpg"
            alt=""
          />

          {locale === "de" ? (
            <img
              className={"_fp-image-slider-badge"}
              src="/images/hero/innovative_through_research_2022-de.png"
              alt=""
            />
          ) : (
            <img
              className={"_fp-image-slider-badge"}
              src="/images/hero/innovative_through_research_2022-en.png"
              alt=""
            />
          )}
        </div>
      </div>
    )
  }
}

export default withTranslation()(ImageSlider)
