import PropTypes from "prop-types"
import React from "react"

import "./business-parameter.sass"

class BusinessParameter extends React.Component {
  constructor(props) {
    super(props)
    this.numberRef = React.createRef()
    this.businessParameterRef = React.createRef()
  }

  componentDidMount() {
    this.businessParameterRef.current.style.minWidth =
      this.numberRef.current.clientWidth + "px"
  }

  render() {
    const Icon = this.props.iconSvgComponent

    return (
      <div ref={this.businessParameterRef} className={"_fp-business-parameter"}>
        <p className="_fp-business-parameter__title">{this.props.title}</p>

        <p
          ref={this.numberRef}
          className="_fp-business-parameter__number _fp-heading-1"
        >
          {Icon}
          {this.props.number}
        </p>
      </div>
    )
  }
}

BusinessParameter.propTypes = {
  number: PropTypes.string,
  title: PropTypes.string,
  iconSvgComponent: PropTypes.node,
}

export default BusinessParameter
