import React from "react"

import { useTranslation } from "../../i18n/translate"
import "./virtual-tour-preview.sass"

export const VirtualTourPreview = () => {
  const { t } = useTranslation()
  return (
    <div className="_fp-virtual-tour-preview">
      <a
        href={`https://my.matterport.com/show/?m=wa3yjDZMY5k`}
        target={"_blank"}
        className="_fp-virtual-tour-preview__container"
      >
        <div className={"_fp-virtual-tour-preview__container__heading"}>
          <p className={"_fp-virtual-tour-preview__container__heading__text"}>
            {t("GLOBAL-VIRTUAL_3D_TOUR-START_CTA")}
          </p>
          <img
            className={"_fp-virtual-tour-preview__container__heading__arrow"}
            src="/images/hero/arrow.svg"
            alt=""
          />
        </div>

        <div className={"_fp-virtual-tour-preview__container__preview"}>
          <video
            poster={"/images/hero/matterport.jpg"}
            muted
            autoPlay={true}
            loop={true}
            playsInline={true}
            controls={false}
            className={"_fp-virtual-tour-preview__container__preview__video"}
          >
            <source src={"/images/hero/matterport.webm"} type={"video/webm"} />
            <source src={"/images/hero/matterport.mp4"} type={"video/mp4"} />
          </video>

          <img
            className={"_fp-virtual-tour-preview__container__preview__play"}
            src="/images/hero/play.svg"
            alt=""
          />
        </div>
      </a>
    </div>
  )
}
