import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import "./slider-indicators.sass"

const SliderIndicators = ({ items, activeIndex, goToIndex }) => {
  if (items.length <= 1) {
    return <></>
  }
  return (
    <div className={"_fp-global-container _fp-slider-indicator-container"}>
      <div className={"_fp-slider-indicators"}>
        {items.map((item, index) => {
          return (
            <button
              key={"slider-indicator" + index}
              onClick={() => goToIndex(index)}
              className={classNames("_fp-slider-indicators__indicator", {
                "_fp-slider-indicators__indicator--active":
                  index === activeIndex,
              })}
            >
              <div className="_fp-slider-indicators__indicator__background" />
            </button>
          )
        })}
      </div>
    </div>
  )
}

SliderIndicators.propTypes = {
  items: PropTypes.array,
  activeIndex: PropTypes.number,
  goToIndex: PropTypes.func,
}

export default SliderIndicators
